:root {
  // font family
  --font-primary: "Montserrat", sans-serif;

  // body font size
  --text-base-size: 1em;

  // type scale
  --text-scale-ratio: 1.2;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(
    var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));

  // line-height
  --body-line-height: 1.6;
  --heading-line-height: 1.4;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;

  --c-primary: #ed1b2f;
}

@supports (--css: variables) {
  :root {
    @include breakpoint(md) {
      --text-base-size: 1.25em;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-primary);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-primary);
  font-weight: 400;

  b {
    font-weight: 700;
  }
}

b {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.small-description {
  @include breakpoint(md) {
    padding: 0 var(--space-xl);
  }
}

small {
}

.c-primary {
  color: var(--color-primary);
}
.c-accent {
  color: var(--color-accent);
}
.c-gray {
  color: var(--color-contrast-medium);
}

.text-nowrap {
  white-space: nowrap;
}
