:root {
  // --btn-font-size: 0.8em;
  // --btn-padding-x: .875em;
  // --btn-padding-y: .75em;

  // --btn-radius: .375em;

  --trans-curve: cubic-bezier(0.165, 0.84, 0.44, 1);
  --trans-time: 0.3s;
}

.btn {
  cursor: pointer;
  font-family: var(--font-primary);
  text-decoration: none;
  user-select: none;
  line-height: 1.4;
  font-weight: 500;
  --color-shadow: hsla(
    var(--color-contrast-higher-h),
    var(--color-contrast-higher-s),
    var(--color-contrast-higher-l),
    0.16
  );
  box-shadow: 0px var(--space-xxxxs) var(--space-xxs) var(--color-shadow),
    0 0 0 transparent;
  transition: box-shadow var(--trans-time) var(--trans-curve);

  &:hover,
  &:focus {
    --color-shadow: hsla(
      var(--color-contrast-higher-h),
      var(--color-contrast-higher-s),
      var(--color-contrast-higher-l),
      0.2
    );
    box-shadow: 0px var(--space-xxxs) var(--space-xs) var(--color-shadow),
      0 0 0 transparent;
  }

  &:focus {
    outline: none;
    box-shadow: 0px var(--space-xxxs) var(--space-xs) var(--color-shadow),
      0 0 0 4px alpha(var(--color-primary), 0.3);
  }

  &:active {
    --color-shadow: hsla(
      var(--color-contrast-higher-h),
      var(--color-contrast-higher-s),
      var(--color-contrast-higher-l),
      0.08
    );
    box-shadow: 0px var(--space-xxxxs) var(--space-xxxs) var(--color-shadow),
      0 0 0 transparent;
  }
}

// themes
.btn--primary {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-white);
  @include fontSmooth;
}

.btn--subtle {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-primary);
  box-shadow: inset 0px 0px 0px 1px var(--color-primary);
  transition: box-shadow var(--trans-time) var(--trans-curve),
    background var(--trans-time) var(--trans-curve),
    color var(--trans-time) var(--trans-curve);

  &:hover,
  &:focus {
    color: var(--color-white);
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    box-shadow: inset 0px 0px 0px 1px var(--color-primary);
  }

  &:focus {
    outline: none;
    box-shadow: inset 0px 0px 0px 1px var(--color-primary),
      0 0 0 4px alpha(var(--color-primary), 0.3);
  }

  &:active {
    color: var(--color-white);
    border-color: var(--color-primary-dark);
    background-color: var(--color-primary-dark);
    box-shadow: inset 0px 0px 0px 1px var(--color-primary-dark);
  }
}

.btn--link {
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0px 0px 0px 0px transparent;
  color: var(--color-primary);
}

.btn--accent {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
  color: var(--color-white);
  @include fontSmooth;
}

// feedback
.btn--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

// size
.btn {
  font-size: var(--btn-font-size, 1em);
}
.btn--sm {
  --btn-font-size: 0.7em;
  padding: 0.575em 0.8571em;
}
.btn--md {
  --btn-font-size: 1.05em;
  padding: 0.8em 1em;
}
.btn--lg {
  // --btn-font-size: 1.1em;
  padding: 1em 2em;
}
