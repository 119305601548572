html {
  scroll-behavior: smooth;
}

body {
  --form-control-radius: 4rem;
  --btn-radius: 4rem;

  overflow-x: hidden;
}

/**
 * Sections
 */
section {
  overflow: hidden;
}
.start-screen {
  background: rgba(173, 232, 255, 1);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(173, 232, 255, 1) 100%
  );

  // Group
  .header__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-md) 0;
    margin-bottom: var(--space-lg);

    @media (min-width: 62rem) {
      flex-wrap: no-wrap;
    }

    .logo {
      width: 100%;

      @media (min-width: 62rem) {
        width: auto;
      }
    }

    .btn--lg {
      @media (max-width: 460px) {
        padding: 1em 1em;
        font-size: 0.85rem;
      }

      @media (max-width: 360px) {
        padding: 1em 1em;
        font-size: 0.8rem;
      }
    }

    .btn__group {
      display: flex;
      // flex-flow: column nowrap;
      flex-flow: row no-wrap;
      align-items: center;
      margin-top: 1rem;
      width: 100%;

      .btn {
        width: 100%;
      }

      > * + * {
        margin-left: 0.375rem;

        @media (min-width: 32rem) {
          margin-left: 1rem;
        }
      }

      @media (min-width: 62rem) {
        flex-flow: row wrap;
        margin-top: 0;
        width: auto;

        .btn {
          width: auto;
        }

        // > * + * {
        //   margin-left: 1rem;
        //   width: auto;
        // }
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: -10rem;
      left: -9rem;
      width: 32rem;
      height: 32rem;
      border: 4rem solid #40d5e6;
      border-radius: 100rem;
      opacity: 0.4;
      z-index: 1;
    }

    > * {
      position: relative;
      z-index: 2;
    }

    .logo {
      margin-right: var(--space-md);

      svg {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .start-screen__content {
    position: relative;
    z-index: 2;
    margin-bottom: var(--space-xl);

    h1,
    h2,
    h3,
    p {
      margin-bottom: 0.875em;
    }

    h3 {
      @media (max-width: 400px) {
        --text-lg: 1.3rem;
      }
    }
  }

  .info__content {
    position: relative;
    z-index: 2;
    @include breakpoint(md) {
      margin: 0 -2rem;
    }

    .card {
      display: flex;
      width: 100%;
      max-width: 100%;
      margin: 0;

      [class^="col-"] {
        margin-bottom: 0;
      }
    }
  }
}

.section--page {
  position: relative;
  padding: var(--space-xl) 0;

  .sub-title {
    max-width: var(--max-width-md);
  }

  .btn {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  // .container {
  //   > * {
  //     &:not(.section--circle) {
  //       position: relative;
  //       z-index: 2;
  //     }
  //   }
  // }
}

.section--circle,
.section--circle-2,
.section--circle-3 {
  overflow: hidden;
  position: absolute;
  width: 28rem;
  height: 28rem;
  top: 1rem;
  right: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -2rem;
    width: 28rem;
    height: 28rem;
    border: 3.7rem solid #40d5e6;
    border-radius: 100rem;
    opacity: 0.4;
  }
}

.section--circle-2 {
  width: 44rem;
  height: 44rem;
  top: 20%;
  right: 0;

  &:after {
    top: 0;
    right: -40%;
    width: 100%;
    height: 100%;
    border: 3.2rem solid #40d5e6;
    opacity: 0.26;
  }
}

.section--circle-3 {
  width: 34rem;
  height: 34rem;
  top: 75%;
  left: 0;

  &:after {
    top: 0;
    left: -20%;
    width: 100%;
    height: 100%;
    border: 3.2rem solid #40d5e6;
    opacity: 0.2;
  }
}

.section--primary {
  background-color: var(--color-primary);
  color: var(--color-white);

  h1,
  h2,
  h3,
  h4 {
    // color: var(--color-white);
    color: inherit;
  }
}

.section--with-border {
  border-bottom: 1px solid var(--color-contrast-low);
}

// @include breakpoint(md) {
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: space-between;
//   align-items: center;

//   .site-logo {
//     margin-bottom: 0;
//   }
// }
